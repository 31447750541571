<template>
    <div class="border border-gray-400 rounded-3xl p-4">
        <div class="font-bold text-xl border-b-2 pb-4">
            <router-link
                :to="{ name: 'AccountReviewIndex' }"
                :class="{ 'text-primary': isActive('AccountReviewIndex') }"
            >
                Waiting For Review
            </router-link>
            <!-- <router-link
                :to="{ name: 'AccountReviewHistory' }"
                class="ml-8"
                :class="{ 'text-primary': isActive('AccountReviewHistory') }"
            >
                My Review
            </router-link> -->
        </div>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: 'AccountReview',

        methods: {
            isActive(name) {
                return this.$route.matched[2].name == name;
            }
        }
    };
</script>
